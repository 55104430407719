import { useFlags } from "launchdarkly-react-client-sdk";

import InsuranceRequests from "../../components/insurance/insuranceRequests/InsuranceRequests";
import LegacyOrdersPage from "../../components/orders/LegacyOrdersPage";
import { OrdersPage as ResponsiveOrdersPage } from "../../components/orders/OrdersPage";

const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

const OrdersPage = () => {
  const { allFleets } = useFlags();

  if (isInsurance) {
    return <InsuranceRequests />;
  }

  if (allFleets) {
    return <ResponsiveOrdersPage />;
  }

  return <LegacyOrdersPage />;
};

export default OrdersPage;
