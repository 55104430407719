import assert from "assert";

import { prettyMonthDayYear } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../../backend/common/workflow.types";
import { useCardRow } from "../../../providers/CardRowProvider";

import ActionButtons from "./ActionButtons";
import Row from "./Row";
import { VehicleRowType } from "./types";

const RequestRowGrid = ({
  fleetVehicle,
  displayShopColumn,
}: {
  fleetVehicle: VehicleRowType;
  displayShopColumn: boolean;
}) => {
  assert(fleetVehicle, "Vehicle not found");
  const nickname = fleetVehicle.carNickname ?? null;
  const { openRequest, roDetailsUrl, startServiceUrl } = useCardRow();
  const { getUnreadMessagesCount } = useUnreadRequests();
  const shopName =
    openRequest?.workflowType === WorkflowType.ServiceUp
      ? "ServiceUp"
      : openRequest?.shop?.name;

  const orderRowContent = [
    {
      content: openRequest?.id?.toString(),
      isBold: true,
      notificationCount: getUnreadMessagesCount(openRequest?.id ?? 0),
    },
    ...(displayShopColumn ? [{ content: shopName }] : []),
    { content: nickname ?? "" },
    {
      content: `${fleetVehicle.year} ${fleetVehicle.make} ${fleetVehicle.model}`,
    },
    {
      content: prettyMonthDayYear(fleetVehicle?.requests?.[0].createdAt, true),
    },
    {
      content: prettyMonthDayYear(openRequest?.customerECD, true),
    },
    { content: fleetVehicle.plate ?? "" },
    { content: fleetVehicle.vin ?? "" },
  ];

  return (
    <Row
      rowContent={orderRowContent}
      actionButtons={<ActionButtons vehicleId={fleetVehicle.vehicleId} />}
      url={roDetailsUrl ?? startServiceUrl}
    />
  );
};

export default RequestRowGrid;
