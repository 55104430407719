import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import useRouterSort from "shared/components/table/TableHeadWithSort/useRouterSort";
import {
  InsuranceRequests_Bool_Exp,
  InsuranceRequests_Order_By,
  Order_By,
  useGetClosedInsuranceRequestsQuery,
  useGetOpenInsuranceRequestsQuery,
} from "shared/generated/graphql";
import { useDebounce } from "shared/lib/utils";

import { useFleets } from "../../../providers/FleetProvider";

export type SortableTableField =
  | "requestId"
  | "claimNumber"
  | "firstName"
  | "policyNumber"
  | "phoneNumber"
  | "email"
  | "createdAt"
  | "endDropoffDate";

export const DEFAULT_ORDER_FIELD: SortableTableField = "requestId";
export const DEFAULT_SORT_DIRECTION = Order_By.Desc;

const apiOrderBy: Record<
  SortableTableField,
  (orderBy: Order_By) => InsuranceRequests_Order_By
> = {
  requestId: (orderBy: Order_By) => ({ id: orderBy }),
  claimNumber: (orderBy: Order_By) => ({ claimNumber: orderBy }),
  firstName: (orderBy: Order_By) => ({
    insured: { firstName: orderBy },
  }),
  policyNumber: (orderBy: Order_By) => ({ policyNumber: orderBy }),
  phoneNumber: (orderBy: Order_By) => ({ insured: { phoneNumber: orderBy } }),
  email: (orderBy: Order_By) => ({ insured: { email: orderBy } }),
  createdAt: (orderBy: Order_By) => ({ request: { createdAt: orderBy } }),
  endDropoffDate: (orderBy: Order_By) => ({
    request: { endDropoffDate: orderBy },
  }),
};

export type InsuranceRequestSearchModel = {
  search: string;
};

const getApiOrderBy = (order: string, orderBy: Order_By) =>
  apiOrderBy[order as SortableTableField]?.(orderBy) ?? undefined;

const useInsuranceRequests = () => {
  const router = useRouter();
  const params = useSearchParams();
  const searchTerm = params.get("search") ?? "";
  const pathName = usePathname();
  const insuranceRequestSearch = {
    search: searchTerm,
  };
  const debouncedSearchTerm = useDebounce(searchTerm);
  const { sortField, sortDirection } = useRouterSort({
    defaultField: DEFAULT_ORDER_FIELD,
    defaultDirection: DEFAULT_SORT_DIRECTION,
  });
  const orderByFilterValues = getApiOrderBy(
    sortField ?? DEFAULT_ORDER_FIELD,
    sortDirection ?? DEFAULT_SORT_DIRECTION
  );
  const { currentFleetIdAsNumber, isValidFleetId } = useFleets();

  const { data, error, loading, refetch } = useGetOpenInsuranceRequestsQuery({
    variables: {
      fleetId: currentFleetIdAsNumber,
      orderBy: orderByFilterValues,
      filter: getSearchFilters(debouncedSearchTerm),
    },
    fetchPolicy: "network-only",
    skip: !isValidFleetId,
  });

  const {
    data: closedInsuranceRequests,
    error: closedInsuranceRequestsError,
    loading: closedInsuranceRequestsLoading,
    refetch: closedInsuranceRequestsRefetch,
  } = useGetClosedInsuranceRequestsQuery({
    variables: {
      fleetId: currentFleetIdAsNumber,
      orderBy: orderByFilterValues,
      filter: getHistorySearchFilters(debouncedSearchTerm),
    },
    fetchPolicy: "network-only",
    skip: !isValidFleetId,
  });

  const updateInsuranceRequestsSearch = (
    newSearch: InsuranceRequestSearchModel
  ) => {
    const newParams = new URLSearchParams(params);

    for (const [key, value] of Object.entries(newSearch)) {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    }

    router.push(`${pathName}?${newParams.toString()}`);
  };

  return {
    openInsuranceRequests: data?.insuranceRequests,
    error,
    loading,
    insuranceRequestSearch,
    updateInsuranceRequestsSearch,
    refetch,
    closedInsuranceRequests: closedInsuranceRequests?.insuranceRequests,
    closedInsuranceRequestsError,
    closedInsuranceRequestsLoading,
    closedInsuranceRequestsRefetch,
  };
};

export default useInsuranceRequests;

function getHistorySearchFilters(search: string): InsuranceRequests_Bool_Exp {
  if (!search) {
    return {};
  }

  const searchTerm = `%${search}%`;

  if (!isNaN(+search)) {
    return {
      _or: [
        {
          requestId: { _eq: +search },
        },
        {
          claimNumber: { _ilike: searchTerm },
        },
        {
          policyNumber: { _ilike: searchTerm },
        },
      ],
    };
  } else {
    return {
      _or: [
        {
          claimNumber: { _ilike: searchTerm },
        },
        {
          insured: { firstName: { _ilike: searchTerm } },
        },
        {
          insured: { lastName: { _ilike: searchTerm } },
        },
        {
          policyNumber: { _ilike: searchTerm },
        },
        {
          request: {
            user: {
              firstName: { _ilike: searchTerm },
            },
          },
        },
        {
          request: {
            user: {
              lastName: { _ilike: searchTerm },
            },
          },
        },
      ],
    };
  }
}

function getSearchFilters(search: string): InsuranceRequests_Bool_Exp {
  if (!search) {
    return {};
  }

  const searchTerm = `%${search}%`;

  if (!isNaN(+search)) {
    const or: InsuranceRequests_Bool_Exp[] = [
      {
        claimNumber: { _ilike: searchTerm },
      },
      {
        policyNumber: { _ilike: searchTerm },
      },
      {
        insured: { phoneNumber: { _ilike: searchTerm } },
      },
    ];
    if (search.length < 10) {
      or.push({
        requestId: { _eq: +search },
      });
    }
    return {
      _or: or,
    };
  } else {
    return {
      _or: [
        {
          claimNumber: { _ilike: searchTerm },
        },
        {
          insured: { firstName: { _ilike: searchTerm } },
        },
        {
          insured: { lastName: { _ilike: searchTerm } },
        },
        {
          policyNumber: { _ilike: searchTerm },
        },
        {
          insured: { phoneNumber: { _ilike: searchTerm } },
        },
        {
          insured: { email: { _ilike: searchTerm } },
        },
      ],
    };
  }
}
