import { useEffect, useMemo, useState } from "react";

import { ApolloError } from "@apollo/client";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import EmptyState from "shared/components/EmptyState/EmptyState";
import SearchBar from "shared/components/table/SearchBar";
import SortButton from "shared/components/table/TableHeadWithSort/SortButton";
import useRouterSort from "shared/components/table/TableHeadWithSort/useRouterSort";
import {
  Order_By,
  Requests_Order_By,
  useGetFleetShopsQuery,
  useGetOpenRequestsLazyQuery,
  UsersFleetVehicles_Bool_Exp,
  UsersFleetVehicles_Order_By,
} from "shared/generated/graphql";
import { MOBILE_MEDIA_QUERY } from "shared/lib/utils";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";

import NeedHelpContactSupportText from "../../components/NeedHelpContactSupportText";
import VehicleViewSelector from "../../components/VehicleViewSelector";
import useFleetSearch from "../../components/fleets/useFleetSearch";
import Tags from "../../components/tags";
import VehicleCardView from "../../components/vehicles/tables/CardView";
import RequestRowGrid from "../../components/vehicles/tables/RequestRowGrid";
import {
  VehicleCardType,
  VehicleRowType,
} from "../../components/vehicles/tables/types";
import { useAccountSettings } from "../../providers/AccountSettings/AccountSettingsProvider";
import { clearServiceStorage } from "../../providers/AccountSettings/storageUtils";
import {
  Settings,
  VehicleView,
} from "../../providers/AccountSettings/vehicleViewUtils";
import { CardRowProvider } from "../../providers/CardRowProvider";
import { useFleets } from "../../providers/FleetProvider";
import { useServices } from "../../providers/ServiceFlowProvider";

type VehicleType = VehicleRowType & VehicleCardType;

const OrdersSection = ({
  vehicles,
  openRequestIds,
  settings,
  isMobile,
  requestsTableHeader,
  sortField,
  sortDirection,
  updateSort,
  displayShopColumn,
}: {
  vehicles: VehicleType[];
  openRequestIds: number[];
  settings: Settings;
  isMobile: boolean;
  requestsTableHeader: {
    label: string;
    fieldName?: string;
    isSortable: boolean;
  }[];
  sortField: string | undefined;
  sortDirection: Order_By;
  updateSort: (sortField?: string, sortDirection?: Order_By) => void;
  displayShopColumn: boolean;
}) => {
  return (
    <Box mb={4}>
      <Box mt={2}>
        <UnreadRequestsProvider initialRequestIds={openRequestIds}>
          {settings.view === VehicleView.Card || isMobile ? (
            <VehicleCardView
              vehicles={vehicles}
              isMobile={isMobile}
              displayCheckboxes={false}
            />
          ) : (
            <Table
              sx={{
                "& .MuiTableCell-sizeMedium": {
                  padding: "0px 20px",
                  height: 50,
                },
                borderSpacing: "0px 20px",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <TableRow>
                  {requestsTableHeader.map((header) => {
                    return (
                      <TableCell key={header.label} sx={{ border: 0 }}>
                        <Stack direction="row" gap={1}>
                          <Typography variant="body2">
                            {header.label}
                          </Typography>
                          {header.isSortable && (
                            <SortButton
                              currentOrder={sortField ?? "carNickname"}
                              order={header.fieldName ?? "carNickname"}
                              orderBy={sortDirection}
                              onClick={updateSort}
                            />
                          )}
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.map((vehicle) => {
                  if (!vehicle) return null;
                  return (
                    <CardRowProvider
                      fleetVehicle={vehicle}
                      key={`vehicle-row-${vehicle.vehicleId}`}
                    >
                      <RequestRowGrid
                        fleetVehicle={vehicle}
                        displayShopColumn={displayShopColumn}
                      />
                    </CardRowProvider>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </UnreadRequestsProvider>
      </Box>
    </Box>
  );
};

const OrdersPage = () => {
  const router = useRouter();
  const { allFleets } = useFlags();
  const { settings } = useAccountSettings();
  const { currentFleetId: fleetId, currentFleets } = useFleets();
  const { clearServices } = useServices();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const { fleetSearch, updateFleetSearch } = useFleetSearch();
  const [openRequests, setOpenRequests] = useState<VehicleType[]>([]);
  const [selectedFleetTags, setSelectedFleetTags] = useState([]);
  const [order, setOrder] = useState<
    Requests_Order_By | UsersFleetVehicles_Order_By
  >({ id: Order_By.Desc });
  const [error, setError] = useState<ApolloError>();
  const [loading, setLoading] = useState(true);

  let queryVariables: number[] | undefined = undefined;
  if (allFleets && fleetId === "all") {
    queryVariables = currentFleets?.map((fleet) => fleet.id);
  } else if (typeof fleetId === "number") {
    queryVariables = [fleetId];
  }

  useEffect(() => {
    clearServices();
    clearServiceStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedFleetTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fleetId]);

  const fleetRequestsCount = openRequests?.length;
  const noFleetRequests = !fleetRequestsCount;
  const openRequestIds = openRequests?.flatMap(
    (v) => v.requests?.flatMap((r) => (r.id ? [r.id] : [])) ?? []
  );

  const [fleetShopsQuery] = [
    useGetFleetShopsQuery({
      variables: {
        fleetIds: queryVariables,
        orderBy: {
          shop: { name: Order_By.Asc },
        },
        filter: {
          shop: { status: { _neq: "New" } },
        },
      },
      skip: !queryVariables,
    }),
  ];

  const displayShopColumn = !!(
    fleetShopsQuery.data?.fleetShops.length &&
    fleetShopsQuery.data?.fleetShops.length > 0
  );

  const requestsTableHeader = [
    {
      label: "Order #",
      fieldName: "id",
      isSortable: true,
    },
    ...(displayShopColumn
      ? [
          {
            label: "Shop",
            fieldName: "shop",
            isSortable: true,
          },
        ]
      : []),
    {
      label: "Name",
      fieldName: "carNickname",
      isSortable: true,
    },
    {
      label: "Vehicle",
      fieldName: "year",
      isSortable: true,
    },
    {
      label: "Request Date",
      fieldName: "addedAt",
      isSortable: true,
    },
    {
      label: "ECD",
      isSortable: false,
    },
    {
      label: "License Plate",
      fieldName: "plate",
      isSortable: true,
    },
    {
      label: "VIN",
      fieldName: "vin",
      isSortable: true,
    },
  ];

  const { sortField, sortDirection, updateSort } = useRouterSort({
    defaultField: "id",
  });

  useEffect(() => {
    const orderCreation: Record<string, string | number> = {};
    const fieldKey = sortField ?? "id";
    orderCreation[fieldKey] = sortDirection;
    setOrder(orderCreation);
  }, [sortField, sortDirection, setOrder]);

  // This is used when sorting with the table headers
  const orderBy = useMemo(() => {
    if (order && "shop" in order) {
      // Order by shop name
      return {
        shop: {
          name:
            order.shop === Order_By.Asc.toString()
              ? Order_By.Asc
              : Order_By.Desc,
        },
        workflowType: Order_By.Desc, // Always show ServiceUp ROs first
      };
    } else if (order?.id) {
      // Order by RO #
      return { createdAt: order?.id };
    } else {
      return { usersFleetVehicles: { ...order } };
    }
  }, [order]);

  const [getRequests] = useGetOpenRequestsLazyQuery({
    fetchPolicy: "network-only",
  });

  function getSearchFilters(
    search: string,
    fleetIds: number[],
    selectedFleetTags: number[]
  ) {
    const vehicleTags =
      selectedFleetTags.length > 0
        ? [{ vehicleTags: { tagId: { _in: selectedFleetTags } } }]
        : [];

    const orData: UsersFleetVehicles_Bool_Exp[] = [
      {
        carNickname: { _ilike: `%${search}%` },
      },
      {
        year: { _ilike: `%${search}%` },
      },
      {
        make: { _ilike: `%${search}%` },
      },
      {
        model: { _ilike: `%${search}%` },
      },
      {
        plate: { _ilike: `%${search}%` },
      },
      {
        vin: { _ilike: `%${search}%` },
      },
      {
        requests: {
          services: {
            description: { _ilike: `%${search}%` },
          },
        },
      },
    ];

    if (search && !isNaN(+search)) {
      orData.push({
        requests: {
          id: { _eq: parseInt(search) },
        },
      });
    }
    return {
      fleetId: fleetIds.length > 1 ? { _in: fleetIds } : { _eq: fleetIds[0] },
      _and: [
        {
          deletedAt: { _is_null: true },
        },
        {
          _or: orData,
        },
        ...vehicleTags,
      ],
    };
  }

  useEffect(() => {
    const fetchRequests = async () => {
      if (!queryVariables) return;
      setLoading(true);
      const { data, error } = await getRequests({
        variables: {
          fleetIds: queryVariables,
          orderBy,
          filter: queryVariables
            ? getSearchFilters(
                fleetSearch.search,
                queryVariables,
                selectedFleetTags
              )
            : {},
        },
      });
      if (error) {
        setError(error);
        setLoading(false);
        return;
      }

      if (data) {
        setOpenRequests(
          data.requests
            .filter((r) => r.usersFleetVehicles)
            .map((request) => ({
              requests: [{ ...request }],
              ...request.usersFleetVehicles?.vehicle,
              ...request.usersFleetVehicles,
            }))
        );
      }

      setLoading(false);
    };

    fetchRequests();
  }, [
    fleetId,
    currentFleets,
    orderBy,
    fleetSearch.search,
    selectedFleetTags,
    getRequests,
    order,
  ]);

  return (
    <Box py={4} px={6}>
      <>
        <Box pb={3}>
          <Grid
            container
            rowGap={1.5}
            columns={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            }}
            justifyContent={"space-between"}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              display={"flex"}
              justifyContent={{ xs: "center", md: "flex-start" }}
              mb={2}
            >
              <SearchBar
                searchTerm={fleetSearch.search}
                onChange={(searchTerm) =>
                  updateFleetSearch({ search: searchTerm })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              lg={2}
              display={"flex"}
              justifyContent={{ xs: "center", md: "flex-end" }}
              mb={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                display={"flex"}
                alignItems={"center"}
              >
                {!isMobile && <VehicleViewSelector />}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ borderColor: "divider", mt: { md: -3, xs: -2 } }} />
        <Tags
          selectedFleetTags={selectedFleetTags}
          setSelectedFleetTags={setSelectedFleetTags}
          showCount={false}
        />
        {error && (
          <Box mt={10}>
            <Stack spacing={1}>
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="h3" textAlign={"center"}>
                  We were unable to retrieve your requests
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="body1" textAlign={"center"}>
                  Please&nbsp;
                  <ButtonBase
                    onClick={() => router.reload()}
                    sx={{ px: 0.2, mt: -0.4 }}
                  >
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      variant="body1"
                      textAlign={"center"}
                    >
                      refresh
                    </Typography>
                  </ButtonBase>
                  &nbsp;the page or logout and log back in.
                </Typography>
              </Box>
            </Stack>
            <Box mt={10} display={"flex"} justifyContent={"center"}>
              <NeedHelpContactSupportText />
            </Box>
          </Box>
        )}
        {loading && (
          <Box mt={10}>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography variant="h6" textAlign={"center"}>
                Loading your requests...
              </Typography>
            </Box>
          </Box>
        )}
        {noFleetRequests && !loading && !error && <EmptyState />}
        {!loading && fleetId && fleetRequestsCount ? (
          <OrdersSection
            vehicles={openRequests}
            openRequestIds={openRequestIds}
            settings={settings}
            isMobile={isMobile}
            requestsTableHeader={requestsTableHeader}
            sortField={sortField}
            sortDirection={sortDirection}
            updateSort={updateSort}
            displayShopColumn={displayShopColumn}
          />
        ) : null}
      </>
    </Box>
  );
};

export default OrdersPage;
