import { Typography } from "@mui/material";

import StyledLink from "shared/components/StyledLink";
import { isReactNativeWebview } from "shared/lib/reactNativeWebviewUtils";

const NeedHelpContactSupport = () => {
  const handleCall = () => {
    if (isReactNativeWebview(window)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          messageType: "call",
          phoneNumber: "+18885640042",
        })
      );
      return;
    }
    window.location.href = "tel:+18885640042";
  };
  return (
    <Typography variant="body2">
      Need help? Contact Support at{" "}
      <StyledLink href={window.location} onClick={handleCall}>
        888-564-0042
      </StyledLink>
    </Typography>
  );
};

export default NeedHelpContactSupport;
