import { useEffect, useState } from "react";

import assert from "assert";

import {
  Badge,
  Box,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";

import { prettyMonthDayYear, prettyPrintPhoneNumber } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import ActionBaseButton from "../../ActionBaseButton";

import { InsuranceRequest } from "./types/insuranceRequests.interfaces";

const InsuranceRequestTableRow = ({
  insuranceRequest,
  fleetId,
}: {
  insuranceRequest: InsuranceRequest;
  fleetId: number | null | undefined;
}) => {
  assert(fleetId, "Team not found");
  const theme = useTheme();
  const router = useRouter();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const { isUnopenedRequest, getUnreadMessagesCount } = useUnreadRequests();
  useEffect(() => {
    if (!insuranceRequest.requestId) return;
    setUnreadMessageCount(getUnreadMessagesCount(insuranceRequest.requestId));
  }, [getUnreadMessagesCount, insuranceRequest.requestId]);

  const roDetailsUrl = insuranceRequest.requestId
    ? {
        pathname: "/request/[requestId]",
        query: { requestId: insuranceRequest.requestId, fleetId },
      }
    : null;

  const insuranceRequestTableCellStyles = {
    border: "none",
  };

  const hoverStyle = isHovered
    ? {
        outline: "solid",
        outlineWidth: 2,
        outlineColor: theme.palette.primary.main,
      }
    : undefined;

  return (
    <TableRow
      onClick={() => router.push(roDetailsUrl ?? "")}
      sx={{
        borderRadius: 3,
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        alignSelf: "center",
        cursor: "pointer",
        alignItems: "center",
        ...hoverStyle,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TableCell
        sx={{
          ...insuranceRequestTableCellStyles,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        }}
      >
        <Stack direction={"row"} spacing={3} ml={1} alignItems="center">
          <Badge
            badgeContent={unreadMessageCount?.toString()}
            max={99}
            invisible={
              !isUnopenedRequest(insuranceRequest.requestId ?? 0) ||
              !unreadMessageCount
            }
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: theme.palette.error.main,
                borderRadius: "50%",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold" }}
            textOverflow="ellipsis"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
          >
            {insuranceRequest.requestId}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {insuranceRequest.claimNumber}
        </Typography>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {prettyMonthDayYear(insuranceRequest.createdAt)}
        </Typography>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {insuranceRequest.insured?.firstName}{" "}
          {insuranceRequest.insured?.lastName}
        </Typography>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {insuranceRequest.policyNumber}
        </Typography>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {prettyPrintPhoneNumber(insuranceRequest.insured?.phoneNumber ?? "")}
        </Typography>
      </TableCell>
      <TableCell sx={insuranceRequestTableCellStyles}>
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {insuranceRequest.insured?.email}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          ...insuranceRequestTableCellStyles,
          padding: 0,
          borderBottomRightRadius: 12,
          borderTopRightRadius: 12,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{
            borderBottomRightRadius: 12,
            borderTopRightRadius: 12,
            overflow: "hidden",
          }}
        >
          <ActionBaseButton />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default InsuranceRequestTableRow;
